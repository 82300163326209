import VisibleLoad from "./VisibleLoad";

function LazyAds({index,page}) {
    return (
        <VisibleLoad onFirstVisible={() => {
            console.log(`start to load ads:${page}-${index}`);
            if(index === 0){
                window.googletag = window.googletag || {cmd: []};
                 window.adSlot2 = window.adSlot2 || null;
                 window.adSlot4 = window.adSlot4 || null;
                if(page === "detail"){
                    let topAd = document.getElementById("adx-0");
                        topAd.innerHTML = `<div id='div-gpt-ad-1706698322517617-0' style='min-width: 300px; min-height: 250px;'>`;
                    if(window.adSlot4 == null){ 
                        window.googletag.cmd.push(function() {
                            window.adSlot4 = window.googletag.defineSlot('/22862194300/newsmingleelitexpress.com/newsmingleelitexpress.com-300x250-013104', [300, 250], 'div-gpt-ad-1706698322517617-0').addService(window.googletag.pubads());
                            window.googletag.pubads().collapseEmptyDivs();
                            window.googletag.enableServices();
                        });
                        window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1706698322517617-0'); });
                     }
                     else{
                        window.googletag.cmd.push(function() {
                            window.googletag.pubads().refresh([window.adSlot4]);
                          });
                     }

                }
                else{
                    let topAd = document.getElementById("adx-0");
                    topAd.innerHTML = `<div id='div-gpt-ad-1706698322690673-0' style='min-width: 300px; min-height: 250px;'>`;
                    if(window.adSlot2 == null){ 
                        window.googletag.cmd.push(function() {
                            window.adSlot2 = window.googletag.defineSlot('/22862194300/newsmingleelitexpress.com/newsmingleelitexpress.com-300x250-013102', [300, 250], 'div-gpt-ad-1706698322690673-0').addService(window.googletag.pubads());
                            window.googletag.pubads().collapseEmptyDivs();
                            window.googletag.enableServices();
                          });
                        window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1706698322690673-0'); });
                     }
                     else{
                        window.googletag.cmd.push(function() {
                            window.googletag.pubads().refresh([window.adSlot2]);
                          });
                     }
                    
                }
                
            }
            /**window._taboola = window._taboola || [];
            window._taboola.push({
                mode: 'thumb-below-mobile',
                container: `taboola-mobile-below-article-thumbnails-${page}-${index}`,
                placement: `Mobile Below Article thumbnails-${page}-${index}`,
                target_type: 'mix'
            });**/
        }}>
            <div id={`adx-${index}`}></div>
        </VisibleLoad>
    )
}

export default LazyAds;